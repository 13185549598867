<template>
  <b-card>
    <g-form @submit="save">
      <b-row>
        <b-col
          v-if="id > 0"
          md="4"
        >
          <g-field
            :value.sync="selectedItem.code"
            label-text="code"
            disabled
          />
        </b-col>
        <!-- transaction Date -->
        <b-col md="4">
          <g-picker
            :value.sync="selectedItem.transactionDate"
            label-text="date"
            name="date"
          />
        </b-col>
        <!-- transaction time -->
        <b-col md="4">
          <g-field
            :value="selectedItem.transactionTime"
            label-text="transactionTime"
            name="transactionTime"
            readonly
          />
        </b-col>
        <b-col md="4">
          <!-- code  -->
          <g-field
            :value.sync="selectedItem.code"
            label-text="code"
            name="code"
          />
        </b-col>
      </b-row>
      <b-row>
        <!-- store from -->
        <b-col md="4">
          <g-field
            :options="stores"
            label-text="storeFrom"
            field="select"
            rules="required"
            name="StoreId"
            :value.sync="selectedItem.storeId"
            :disabled="selectedItem.lines.length > 0"
            :dir="isRight ? 'rtl' : 'ltr'"
            :label="isRight ? 'arabicName' : 'englishName'"
            @change="() => {
              if (!beforeSaveValidationFrom()) return;
            }"
          />
        </b-col>
        <!-- store to -->
        <b-col md="4">
          <g-field
            :options="stores"
            :disabled="(selectedItem.lines.length > 0 && !!selectedItem.destinationStoreId)"
            label-text="storeTo"
            field="select"
            rules="required"
            name="StoreId"
            :value.sync="selectedItem.destinationStoreId"
            :dir="isRight ? 'rtl' : 'ltr'"
            :label="isRight ? 'arabicName' : 'englishName'"
            @change="() => {
              if (!beforeSaveValidationTo()) return;
            }"
          />
        </b-col>
        <b-col
          v-if="id"
          md="4"
        >
          <g-field
            label-text="createdBy"
            :value="selectedItem.createdUserName"
            placeholder="createdBy"
            name="createdBy"
            readonly
          />
        </b-col>
        <b-col
          v-if="id && selectedItem.updatedUserName"
          md="4"
        >
          <g-field
            label-text="updatedBy"
            :value="selectedItem.updatedUserName"
            placeholder="updatedBy"
            name="updatedBy"
            readonly
          />
        </b-col>
      </b-row>
      <b-row class="pt-1">
        <!-- notes -->
        <b-col md="12">
          <b-form-group :label="$t('notes')">
            <b-form-textarea
              v-model="selectedItem.notes"
              label-text="notes"
              rows="3"
              max-rows="6"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col cols="12">
          <items
            :store-id="selectedItem.storeId"
            :destination-store-id="selectedItem.destinationStoreId"
            :items.sync="selectedItem.lines"
            :transaction-type="selectedItem.transactionType"
          />
        </b-col>
        <b-col cols="12">
          <hr>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          class="d-flex justify-content-end"
        >
          <b-button
            v-if="id || currentBranch.isMaster"
            class="ml-1"
            variant="outline-primary"
            :disabled="selectedItem.lines.length <= 0"
            @click="print(id)"
          >
            <feather-icon
              icon="PrinterIcon"
              class="mr-50"
            />
            {{ $t('print') }}
          </b-button>
          <b-button
            v-if="!selectedItem.isPosted"
            v-permission="$route.meta.permission"
            class="ml-1"
            variant="outline-primary"
            data-action-type="saveAndPrint"
            :disabled="!selectedItem.lines.length"
            @click="save('saveAndPrint')"
          >
            <feather-icon
              icon="PrinterIcon"
              class="mr-50"
            />
            {{ $t('saveAndPrint') }}
          </b-button>
          <b-button
            v-if="!selectedItem.isPosted"
            v-permission="$route.meta.permission"
            class="ml-1"
            type="submit"
            variant="relief-primary"
            data-action-type="save"
            :disabled="!selectedItem.lines.length"
          >
            <feather-icon
              icon="SaveIcon"
              class="mr-50"
            />
            {{ $t('save') }}
          </b-button>
        </b-col>
      </b-row>
    </g-form>
  </b-card>
</template>

<script>
import reportMixin from '@/mixin/reportMixin';
import items from './components/items.vue';

export default {
  components: {
      items,
  },
  mixins: [reportMixin],
  props: ['id'],
  data() {
    return {
      items: [],
      stores: [],
        // storageTransfer Model
      selectedItem: {
        code: '',
        branchId: this.branchId,
        transactionDate: '',
        transactionTime: '',
        notes: '',
        transactionType: 'storageTransfer',
        storeId: 0,
        destinationStoreId: 0,
        total: 0,
        discountPercentage: 0,
        net: 0,
        lines: [],
      }
    };
  },
  computed: {
  },
  mounted() {
     this.selectedItem.transactionDate = this.today;
     this.selectedItem.transactionTime = this.time;
     this.getData();
      if (this.id) {
      this.get({ url: `ItemTransactions/storageTransfer/${this.id}` }).then(
        (data) => {
          this.selectedItem = data;
          data.lines.forEach((item) => {
            item.transactionId = this.selectedItem.id;
            item.costPrice = this.fraction(item.total / item.quantity);
            item.units = [
              {
                unitId: item.unitId,
                unitArabicName: item.unitArabicName,
                unitEnglishName: item.unitEnglishName,
                price: item.salesPrice,
                minSalesPrice: item.minSalesPrice,
                maxSalesPrice: item.maxSalesPrice,
                barcode: item.mainUnitBarcode,
                isMain: true,
              },
            ];
          });
        }
      );
    }
  },
  methods: {
    beforeSaveValidationTo() {
      if (this.selectedItem.destinationStoreId === this.selectedItem.storeId) {
        // alert here
        this.doneAlert({ text: this.$t('canNotTransferToTheSameStore'), type: 'error' });
        this.selectedItem.destinationStoreId = 0;
        return false;
      }
      return true;
    },
    beforeSaveValidationFrom() {
      if (this.selectedItem.destinationStoreId === this.selectedItem.storeId) {
        // alert here
        this.doneAlert({ text: this.$t('canNotTransferToTheSameStore'), type: 'error' });
        this.selectedItem.storeId = 0;
        return false;
      }
      return true;
    },
    getData() {
        this.get({ url: 'Stores' }).then((data) => {
          this.stores = data;
        });
    },
    checkStore() {
      if (this.selectedItem.storeId === this.selectedItem.destinationStoreId) {
        this.selectedItem.destinationStoreId = 0;
        this.doneAlert({ text: this.$t('canNotTransferToTheSameStore'), type: 'error' });
      }
    },
    save(type) {
        let errMsg = '';
      if (this.selectedItem.storeId === this.selectedItem.destinationStoreId) errMsg = 'canNotTransferToTheSameStore';
      else if (this.selectedItem.lines.length === 0) errMsg = 'youMustChooseOneItemAtLeast';
      if (errMsg) {
        this.doneAlert({ text: this.$t(errMsg), type: 'error' });
        return;
      }
      if (this.selectedItem.id) {
        this.update({
          url: 'ItemTransactions/storageTransfer',
          data: this.selectedItem,
          id: this.selectedItem.id,
        })
          .then(() => {
            this.doneAlert({ text: this.$t('updatedSuccessfully') });
            if (type === 'saveAndPrint') this.print(this.selectedItem.id);
            this.$router.push({ name: 'storage-transfer' });
          });
      } else {
        this.create({
          url: 'ItemTransactions/storageTransfer',
          data: this.selectedItem,
        })
          .then((data) => {
            this.doneAlert({ text: this.$t('savedSuccessfully') });
            if (type === 'saveAndPrint') this.print(data.id);
            this.$router.push({ name: 'storage-transfer' });
          });
      }
    },
     print(id) {
      const printedItem = {
        id: id
      }
       this.printReport('StorageTransfer-ar', printedItem);
    },
  },
};
</script>
